<template>
  <div class="anchor-container">
    <div class="review_head">
      <div class="left"><a href="/review">杭州魔筷科技有限公司</a></div>
      <div class="right"><a href="/about-copy">关于我们</a></div>
    </div>
    <div id="anchor-fullpage">
      <div ref="firstSection" class="section fp-auto-height-responsive">
        <service-section></service-section>
      </div>
      <div class="section fp-auto-height-responsive">
        <cases-section></cases-section>
      </div>
      <div class="section fp-auto-height-responsive">
        <study-section></study-section>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from "js-base64";
import ServiceSection from "@/components/review/ServiceSection.vue";
import CasesSection from "@/components/review/CasesSection.vue";
import StudySection from "@/components/review/StudySection.vue";

let isFirstSection = false;
// let anchorMove = false;

export default {
  head: {
    title: "主播服务—为主播提供专业定制化的服务",
    meta: [
      {
        name: "Keywords",
        content:
          "选品选货  流量运营 内容策划  直播运营  售后管理  营销活动  直播专场   专业培训",
      },
      {
        name: "Description",
        content:
          "魔筷提供专业的直播服务，从选品选货到内容策划培训等提供直播全链路服务，直播选货，就用魔筷！",
      },
    ],
  },
  components: {
    ServiceSection,
    CasesSection,
    StudySection,
  },
  mounted() {
    // this.initFullpage();
  },
  destroyed() {
    window.fullpage_api.destroy();
  },
  methods: {
    initFullpage() {
      require("fullpage.js/vendors/scrolloverflow.min.js");
      const Fullpage = require("fullpage.js");
      const self = this;
      new Fullpage("#anchor-fullpage", {
        licenseKey: decode(window.__INITIAL_STATE__.fullpageKey),
        anchors: ["service", "cases", "study"],
        menu: ".sub-header .nav",
        verticalCentered: false,
        scrollOverflow: true,
        scrollOverflowOptions: {
          probeType: 3,
        },
        touchSensitivity: 80,
        fixedElements: ".anchor-header, .qrcode-modal, .video-modal",
        // paddingTop: '150px',
        afterRender() {
          window.fullpage_api.reBuild();
          window.addEventListener("wheel", () => {
            // anchorMove = false;
            // console.log('wheel', e)
            if (isFirstSection) {
              let ele = self.$refs.firstSection.querySelector(
                ".fp-scrollable .fp-scroller"
              );
              // let ele = document.querySelector('.fp-section.active .fp-scrollable .fp-scroller')
              const a = window
                .getComputedStyle(ele, null)
                .getPropertyValue("transform");
              // console.log(a)
              let y = Math.abs(
                a.split(",")[a.split(",").length - 1].split(")")[0]
              );
              console.log(y);

              window.fullpage_api.reBuild();
            }
          });
        },
        afterLoad() {
          // console.log("afterload", origin, dest, dir);
          // let iscroll =
          //   document.querySelector(".fp-section.active .fp-scrollable") &&
          //   document.querySelector(".fp-section.active .fp-scrollable")
          //     .fp_iscrollInstance;
          // console.log(iscroll);
          // if (anchorMove && iscroll) {
          //   iscroll.scrollTo(0, 0, 600);
          // }
          // isFirstSection = dest.isFirst;
          // if (dest.isFirst) {
          // self.$refs.header.style.top = '0'
          // } else {
          // self.$refs.header.style.top = '-90px'
          // }
        },
        afterReBuild: () => {
          console.log("fullpage rebuild!");
        },
        onLeave() {
          // console.log(origin, dest, dir);
          // if (!dest.isFirst) {
          //   self.$refs.header.style.top = '-90px'
          // } else {
          //   if (dir === 'up') {
          //     self.$refs.header.style.top = '-90px'
          //   }
          // }
        },
      });
    },
    onBacktop() {
      window.fullpage_api.moveTo(1);
      let iscroll =
        document.querySelector(".fp-section.active .fp-scrollable") &&
        document.querySelector(".fp-section.active .fp-scrollable")
          .fp_iscrollInstance;
      if (iscroll) {
        iscroll.scrollTo(0, 0, 600);
      }
    },
    changeMoveMode() {
      // anchorMove = true;
    },
  },
};
</script>


<style lang="less" scoped>
.box {
  display: flex;
  align-items: center;
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 9px;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6600;
  }
}
.anchor-container {
  .review_head {
    height: 90px;
    display: flex;
    justify-content: space-between;
    margin: 0 240px;
    align-items: center;
    font-size: 1.5vw;
    font-weight: 600;
    color: #333;

    .left,
    .right {
      cursor: pointer;
      a {
        text-decoration: none;
        color: #333;
      }
    }

    .right {
      font-weight: 400;
    }
    .right:hover {
      border-bottom: 3px solid #ff6600;
    }
  }
  .section {
    transition: all 0.5s;

    &:nth-child(2) {
      padding-top: 60px;
      background-color: #fff;
    }
    &:nth-child(3) {
      padding-top: 60px;
      background-color: #fff;
    }
  }
}
</style>

