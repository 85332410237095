<template>
  <div class="cases-wrapper">
    <div class="data-banner-wrapper">
      <h2 class="title">他们说：“魔筷，让梦想近在咫尺！”</h2>
      <div class="desc">
        截止2021年，魔筷已服务过数百万主播。他们有的来自农村，有的来自国外，有的是单亲妈妈，有的是时尚达人……但无一例外的是，他们怀揣梦想，且深爱直播电商行业。魔筷以专业和贴心的服务能力与他们相互成就、共同成长！
      </div>
      <div class="data-box">
        <div v-for="(item, index) in casesData" :key="index" class="data-item">
          <div class="num-line">
            <div class="num">
              <count-to
                :ref="`count${index}`"
                :key="index"
                class="num"
                separator=""
                :startVal="0"
                :endVal="item.num"
                :duration="1200"
              ></count-to>
            </div>
            <div v-if="item.unit" class="unit">{{ item.unit }}</div>
            <div v-if="item.plus" class="plus">+</div>
          </div>
          <div class="text-line">{{ item.desc }}</div>
        </div>
      </div>
      <div class="anchor-title">主播故事</div>
      <div class="anchor-desc">看见腰部主播的力量</div>
    </div>
    <div
      class="anchor-cases-wrapper"
      @mouseenter="onSwiperEnter"
      @mouseleave="onSwiperLeave"
    >
      <div class="swiper-arrow prev"></div>
      <div class="swiper-arrow next"></div>
      <div class="swiper-pagination"></div>
      <swiper ref="caseSwiper" :options="casesSwiperOption">
        <swiper-slide v-for="(item, index) in casesSwiperData" :key="index">
          <div class="case-item">
            <div
              class="image"
              :style="{ backgroundImage: `url(${item.img})` }"
              @click="showVideo(item)"
            >
              <img
                src="https://mktv-in-cdn.mockuai.com/16267470199756939.png"
                alt=""
                class="play-icon"
              />
            </div>
            <div class="detail">
              <div class="title">{{ item.title }}</div>
              <div class="data-box">
                <div
                  v-for="(dataItem, i) in item.data"
                  :key="i"
                  class="data-item"
                >
                  <div class="num-line">
                    <div v-if="dataItem.num > 5" class="num">
                      <count-to
                        :ref="`count${i}`"
                        :key="index"
                        class="num"
                        separator=""
                        :startVal="0"
                        :endVal="dataItem.num"
                        :duration="1200"
                      ></count-to>
                    </div>
                    <div v-else class="num">{{ dataItem.num }}</div>
                    <div v-if="dataItem.unit" class="unit">
                      {{ dataItem.unit }}
                    </div>
                    <div v-if="dataItem.plus" class="plus">+</div>
                  </div>
                  <div class="text-line">{{ dataItem.desc }}</div>
                </div>
              </div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="backsource-wrapper">
      <img
        src="https://mktv-in-cdn.mockuai.com/16252809100259479.png"
        alt=""
        class="act"
      />
      <h2 class="title">溯源好货</h2>
      <div class="desc">遍访全国，挖掘源头好物！</div>
      <div class="main">
        <div class="info">
          <div class="data-box">
            <div
              v-for="(item, index) in backsourceData.data"
              :key="index"
              class="data-item"
            >
              <div class="num-line">
                <count-to
                  :ref="`count${index}`"
                  :key="index"
                  class="num"
                  separator=""
                  :startVal="0"
                  :endVal="item.num"
                  :duration="1200"
                ></count-to>
                <div v-if="item.unit" class="unit">{{ item.unit }}</div>
                <div v-if="item.plus" class="plus">+</div>
              </div>
              <div class="text-line">{{ item.desc }}</div>
            </div>
          </div>
          <div class="desc">{{ backsourceData.desc }}</div>
        </div>
      </div>
      <div class="mobile">
        <mobile-video-box
          src="https://mktv-in-cdn.mockuai.com/16255536991846999.mp4"
        >
          <img
            src="https://mktv-in-cdn.mockuai.com/16252291643007185.png"
            alt=""
            class="decorate decorate-1"
          />
          <img
            src="https://mktv-in-cdn.mockuai.com/16252291643049517.png"
            alt=""
            class="decorate decorate-2"
          />
          <img
            src="https://mktv-in-cdn.mockuai.com/16252291643024771.png"
            alt=""
            class="decorate decorate-3"
          />
        </mobile-video-box>
      </div>
    </div>
    <div class="swiper-section marketing-activity">
      <img
        src="https://mktv-in-cdn.mockuai.com/16252809100259479.png"
        alt=""
        class="act"
      />
      <h2 class="title">营销活动</h2>
      <div class="desc">每场活动，都在成就更好的你！</div>
      <div class="swiper">
        <div class="act-swiper-arrow prev"></div>
        <div class="act-swiper-arrow next"></div>
        <div class="act-swiper-pagination"></div>
        <swiper :options="marketingActivitySwiperOption">
          <swiper-slide
            v-for="(item, index) in marketingActivitySwiperData"
            :key="index"
          >
            <div class="card-item">
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="data-box">
                  <div
                    v-for="(data, i) in item.data"
                    :key="i"
                    class="data-item"
                  >
                    <div class="num-line">
                      <count-to
                        :ref="`count${i}`"
                        :key="index"
                        class="num"
                        separator=""
                        :startVal="0"
                        :endVal="data.num"
                        :duration="1200"
                      ></count-to>
                      <div v-if="data.unit" class="unit">{{ data.unit }}</div>
                      <div v-if="data.plus" class="plus">+</div>
                    </div>
                    <div class="text-line">{{ data.desc }}</div>
                  </div>
                </div>
                <div class="desc">{{ item.desc }}</div>
              </div>
              <div class="mobile">
                <mobile-video-box :src="item.src" ref="mobile_video">
                  <img
                    v-for="(img, i) in item.decorates"
                    :key="i"
                    :src="img.url"
                    alt=""
                    class="decorate"
                    :class="img.class"
                  />
                </mobile-video-box>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="swiper-section live-broadcast">
      <img
        src="https://mktv-in-cdn.mockuai.com/16252809100259479.png"
        alt=""
        class="act"
      />
      <h2 class="title">直播专场</h2>
      <div class="desc">每次开播，都是你我共同收获的时刻！</div>
      <div class="swiper">
        <div class="act-swiper-arrow prev"></div>
        <div class="act-swiper-arrow next"></div>
        <div class="act-swiper-pagination"></div>
        <swiper :options="liveBroadcastSwiperOption">
          <swiper-slide
            v-for="(item, index) in liveBroadcastSwiperData"
            :key="index"
          >
            <div class="card-item">
              <div class="mobile">
                <mobile-video-box :data-src="item.src">
                  <img
                    v-for="(img, i) in item.decorates"
                    :key="i"
                    :src="img.url"
                    alt=""
                    class="decorate"
                    :class="img.class"
                  />
                </mobile-video-box>
              </div>
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="data-box">
                  <div
                    v-for="(data, i) in item.data"
                    :key="i"
                    class="data-item"
                  >
                    <div class="num-line">
                      <div v-if="data.text" class="text">{{ data.text }}</div>
                      <count-to
                        :ref="`count${i}`"
                        :key="index"
                        class="num"
                        separator=""
                        :startVal="0"
                        :endVal="data.num"
                        :duration="1200"
                      ></count-to>
                      <div v-if="data.unit" class="unit">{{ data.unit }}</div>
                      <div v-if="data.plus" class="plus">+</div>
                    </div>
                    <div class="text-line">{{ data.desc }}</div>
                  </div>
                </div>
                <div class="desc">{{ item.desc }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <video-modal
      :visible="videoModalVisible"
      :src="videoSrc"
      @close="closeVideo"
    ></video-modal>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import MobileVideoBox from "@/components/MobileVideoBox.vue";
import VideoModal from "@/components/VideoModal.vue";

export default {
  components: {
    CountTo,
    MobileVideoBox,
    VideoModal,
  },
  data() {
    return {
      videoSrc: "",
      videoModalVisible: false,
      casesData: [
        {
          num: 300,
          unit: "万",
          plus: true,
          desc: "服务主播数",
        },
        {
          num: 10,
          unit: "万",
          plus: true,
          desc: "累计服务专场次数",
        },
        {
          num: 100,
          plus: true,
          desc: "过亿GMV活动案例",
        },
        {
          num: 100,
          unit: "万小时",
          plus: true,
          desc: "平台主播月平均直播时长",
        },
      ],
      casesSwiperOption: {
        speed: 2000,
        spaceBetween: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // loop: true,
        navigation: {
          nextEl: ".swiper-arrow.next",
          prevEl: ".swiper-arrow.prev",
        },
        pagination: {
          el: ".anchor-cases-wrapper .swiper-pagination",
          clickable: true,
        },
        // effect: 'fade'
      },
      casesSwiperData: [
        {
          title: "“人间真实”毛楞姐",
          img: "https://mktv-in-cdn.mockuai.com/16252755829311013.png",
          video: "https://mktv-in-cdn.mockuai.com/16267466454804966.mp4",
          data: [
            {
              num: 200,
              unit: "万",
              desc: "粉丝量",
            },
            {
              num: 1000,
              unit: "万",
              plus: true,
              desc: "月均成交能力",
            },
            {
              num: 2,
              unit: "年",
              desc: "入驻时长",
            },
          ],
          desc: "从实体店老板娘转型为直播带货达人，一年拍上千条短视频。最让毛楞姐暖心的是魔筷的服务和售后，能替主播看到他们看不到的方向。",
        },
        {
          title: "“拼命三娘“婉婉吖",
          img: "https://mktv-in-cdn.mockuai.com/16252756995737691.png",
          video: "https://mktv-in-cdn.mockuai.com/16267467999957193.mp4",
          data: [
            {
              num: 66,
              unit: "万",
              desc: "粉丝量",
            },
            {
              num: 300,
              unit: "万",
              plus: true,
              desc: "月均成交能力",
            },
            {
              num: 1,
              unit: "年",
              desc: "入驻时长",
            },
          ],
          desc: "生产线上的打工妹，在进入直播行业前，一个月仅挣着三千的工资却只敢花一千去养活两个孩子。在进入直播行业后，一年的时间累积了30万的粉丝。在自己的努力下，并通过魔筷的服务，脱离了捉襟见肘的日子，与孩子们过上了富足的生活。",
        },
        {
          title: "“外刚内柔”蛋蛋妈",
          img: "https://mktv-in-cdn.mockuai.com/16267462804436412.png",
          video: "https://mktv-in-cdn.mockuai.com/16267469994297023.mp4",
          data: [
            {
              num: 50,
              unit: "万",
              desc: "粉丝量",
            },
            {
              num: 400,
              unit: "万",
              plus: true,
              desc: "月均成交能力",
            },
            {
              num: 2,
              unit: "年",
              desc: "入驻时长",
            },
          ],
          desc: "她是外人看来阳光要强的大姐大，生活井井有条，直播能说会道，不理解她的人骂她，懂她的人却只会心疼她。该视频带你走进快手主播蛋蛋妈妈的内心世界，听听她那些不为人知的故事，看看她”强势“外表下那颗柔软又温暖的心。",
        },
      ],
      backsourceData: {
        data: [
          {
            num: 30,
            unit: "万",
            plus: true,
            desc: "日均成交单数",
          },
          {
            num: 10000,
            plus: true,
            desc: "参与主播数",
          },
          {
            num: 1000,
            plus: true,
            desc: "参与工厂数",
          },
        ],
        desc: "溯源活动已成为主播专属的活动项目。截止2021年初，魔筷与主播共同探访了数以千计的优质工厂和原产地，亲临包括：螺蛳粉、苹果、海鲜等多个类目的生产和原料基地。主播们走遍中国的每一个角落，深入了解地方特色文化，并将优质的源头好货带给粉丝，实现了令人瞩目的增长。",
      },
      marketingActivitySwiperOption: {
        navigation: {
          nextEl: ".marketing-activity .act-swiper-arrow.next",
          prevEl: ".marketing-activity .act-swiper-arrow.prev",
        },
        pagination: {
          el: ".marketing-activity .act-swiper-pagination",
          clickable: true,
        },
      },
      marketingActivitySwiperData: [
        {
          src: "https://mktv-in-cdn.mockuai.com/16255537177625071.mp4",
          title: "魔筷年货节",
          data: [
            {
              num: 10,
              unit: "亿",
              plus: true,
              desc: "活动总GMV",
            },
            {
              num: 10000,
              plus: true,
              desc: "活动SKU",
            },
            {
              num: 800,
              unit: "%",
              plus: true,
              desc: "参与主播GMV同期增长",
            },
          ],
          desc: "2020年底，魔筷年货节在三亚盛大举办。活动共聚集“线上+线下”数百位主播，组织和安排50场专场直播，帮助主播在短期内收获大量粉丝，并最终在15天的时间里创造出10亿元GMV的好成绩。",
          decorates: [
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642961907.png",
              class: "decorate-a",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291643049517.png",
              class: "decorate-b",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642983618.png",
              class: "decorate-c",
            },
          ],
        },
        {
          src: "https://mktv-in-cdn.mockuai.com/16255537177859354.mp4",
          title: "“爆款国潮”年中活动",
          data: [
            {
              num: 2,
              unit: "亿",
              plus: true,
              desc: "活动总GMV",
            },
            {
              num: 10000,
              plus: true,
              desc: "活动SKU",
            },
            {
              num: 2.7,
              unit: "亿",
              plus: true,
              desc: "活动总曝光",
            },
          ],
          desc: "2021年，魔筷在杭州举办“爆款国潮”直播活动。千款优质品牌国货均参与此次活动，其中包括珀莱雅、晨光、华味亨等。为此，魔筷在线上和线下召集上百位优质主播以及数千位成长型主播，组织和安排60场线下直播专场，2场大主播专场，并完成了4亿元GMV！",
          decorates: [
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642961907.png",
              class: "decorate-a",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291643049517.png",
              class: "decorate-b",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642938180.png",
              class: "decorate-c",
            },
          ],
        },
      ],
      liveBroadcastSwiperOption: {
        navigation: {
          nextEl: ".live-broadcast .act-swiper-arrow.next",
          prevEl: ".live-broadcast .act-swiper-arrow.prev",
        },
        pagination: {
          el: ".live-broadcast .act-swiper-pagination",
          clickable: true,
        },
      },
      liveBroadcastSwiperData: [
        {
          src: "https://mktv-in-cdn.mockuai.com/16255537177882831.mp4",
          title: "小沈龙直播专场",
          data: [
            {
              num: 3300,
              unit: "万",
              desc: "5小时GMV",
            },
            {
              num: 30,
              unit: "万",
              plus: true,
              desc: "在线人数",
            },
          ],
          desc: "2020年魔筷5周年庆期间，魔筷与小沈龙共同组织策划直播专场活动。为此，魔筷特邀女神“周韦彤”到现场，为小沈龙引流。同时，魔筷精心策划直播间脚本，并仔细进行选品组货，确保直播节奏和维护在线流量。活动结束后，小沈龙对魔筷的贴心服务表示十分认可。",
          decorates: [
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642784040.png",
              class: "decorate-d",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291643049517.png",
              class: "decorate-e",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642839484.png",
              class: "decorate-f",
            },
          ],
        },
        {
          src: "https://mktv-in-cdn.mockuai.com/16255537177918545.mp4",
          title: "浪胃仙直播专场",
          data: [
            {
              text: "第",
              num: 1,
              unit: "名",
              desc: "快手热搜榜",
            },
            {
              num: 100,
              unit: "万",
              plus: true,
              desc: "在线人数",
            },
          ],
          desc: "2019年底，魔筷举办浪胃仙直播专场活动，并邀请著名笑星杨迪共同参与。直播开始后，直播间人气迅速攀升，不到5分钟就占据快手热搜榜第一名。这些都依托于魔筷的前期预热能力以及直播间的运营能力。据浪胃仙的运营人员反应，本场活动是浪胃仙在快手上人气最高的专场之一。",
          decorates: [
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642784040.png",
              class: "decorate-d",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291643049517.png",
              class: "decorate-e",
            },
            {
              url: "https://mktv-in-cdn.mockuai.com/16252291642752698.png",
              class: "decorate-f",
            },
          ],
        },
      ],
    };
  },
  computed: {
    caseSwiper() {
      return this.$refs.caseSwiper.$swiper;
    },
  },
  mounted() {
    this.$refs.mobile_video[0].$refs.video.play();
    this.$refs.mobile_video[1].$refs.video.play();
  },
  methods: {
    onSwiperEnter() {
      this.caseSwiper.autoplay.stop();
    },
    onSwiperLeave() {
      this.caseSwiper.autoplay.start();
    },
    showVideo(item) {
      this.videoSrc = item.video;
      this.videoModalVisible = true;
    },
    closeVideo() {
      this.videoModalVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/common.less";

.cases-wrapper {
  background-color: #fff;
  .data-banner-wrapper {
    box-sizing: border-box;
    .pxToVw(height, 660);
    .pxToVw(padding-top, 94);
    .pxToVw(padding-bottom, 123);
    background-color: #00092a;
    background-image: url(https://mktv-in-cdn.mockuai.com/16252259933903902.png);
    background-size: cover;
    background-repeat: no-repeat;
    h2.title {
      margin: 0;
      .pxToVw(margin-bottom, 28);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    > .desc {
      .pxToVw(width, 1266);
      margin: 0 auto 28 * @unitVw;
      text-align: center;
      .pxToVw(line-height, 25);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      opacity: 0.6;
    }
    .data-box {
      display: flex;
      align-items: center;
      .pxToVw(margin-left, 465);
      .data-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .num-line {
          display: flex;
          align-items: flex-end;
          .num {
            .pxToVw(line-height, 68);
            .pxToVw(font-size, 60);
            font-family: DINCond-Light, DINCond;
            font-weight: 300;
            color: #ffffff;
          }
          .unit,
          .plus {
            line-height: 1.4;
            .pxToVw(font-size, 20);
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #fff;
          }
          .unit {
            .pxToVw(margin-bottom, 10);
          }
          .plus {
            align-self: center;
          }
        }
        .text-line {
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        &:nth-child(1) {
          .pxToVw(width, 96);
          .pxToVw(margin-right, 183);
        }
        &:nth-child(2) {
          .pxToVw(width, 150);
          .pxToVw(margin-right, 154);
        }
        &:nth-child(3) {
          .pxToVw(width, 156);
          .pxToVw(margin-right, 124);
        }
        &:nth-child(4) {
          .pxToVw(width, 208);
        }
      }
    }
    .anchor-title {
      text-align: center;
      margin: 88 * @unitVw auto 8 * @unitVw;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff6600;
    }
    .anchor-desc {
      text-align: center;
      line-height: 1.4;
      .pxToVw(font-size, 20);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .anchor-cases-wrapper {
    box-sizing: border-box;
    position: relative;
    .pxToVw(top, -60);
    .pxToVw(width, 1440);
    .pxToVw(height, 590);
    .pxToVw(padding, 60);
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.09);
    .pxToVw(border-radius, 16);
    .swiper-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .pxToVw(width, 44);
      .pxToVw(height, 44);
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      &.swiper-button-disabled {
        display: none;
      }
      &.prev {
        .pxToVw(left, 13);
        background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
      }
      &.next {
        .pxToVw(right, 18);
        background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
      }
    }
    .swiper-pagination {
      .pxToVw(bottom, 60);
      left: 50%;
      transform: translateX(-50%);
      /deep/ .swiper-pagination-bullet {
        .pxToVw(width, 24);
        .pxToVw(height, 6);
        .pxToVw(margin-right, 8);
        .pxToVw(border-radius, 4);
        background-color: #ddd;
        &:last-child {
          margin-right: 0;
        }
        &.swiper-pagination-bullet-active {
          background-color: #ff6600;
        }
      }
    }
    .swiper-container {
      .pxToVw(width, 1200);
    }
    .case-item {
      display: flex;
      align-items: center;
      background-color: #fff;
      .image {
        position: relative;
        // width: 37.5vw;
        flex: 0 0 720 * @unitVw;
        .pxToVw(width, 720);
        .pxToVw(height, 404);
        .pxToVw(margin-right, 40);
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        .main {
          width: 100%;
        }
        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .pxToVw(width, 60);
          .pxToVw(height, 60);
        }
      }
      .detail {
        .title {
          .pxToVw(margin-bottom, 42);
          .pxToVw(line-height, 50);
          .pxToVw(font-size, 36);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .data-box {
          display: flex;
          align-items: center;
          .data-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .pxToVw(margin-right, 80);
            &:last-child {
              margin-right: 0;
            }
            .num-line {
              display: flex;
              align-items: flex-end;
              .num {
                .pxToVw(line-height, 68);
                .pxToVw(font-size, 60);
                font-family: DINCond-Light, DINCond;
                font-weight: 300;
                color: #333333;
              }
              .unit,
              .plus {
                line-height: 1.4;
                .pxToVw(font-size, 20);
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 300;
                color: #333;
              }
              .unit {
                .pxToVw(margin-bottom, 10);
              }
              .plus {
                align-self: center;
              }
            }
            .text-line {
              .pxToVw(line-height, 25);
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
            }
          }
        }
        .desc {
          .pxToVw(width, 440);
          .pxToVw(margin-top, 40);
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #666666;
        }
      }
    }
  }
  .backsource-wrapper {
    box-sizing: border-box;
    position: relative;
    .pxToVw(height, 798);
    .pxToVw(padding-top, 26);
    .act {
      position: absolute;
      // z-index: -1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      .pxToVw(width, 423);
    }
    > h2.title {
      margin: 0;
      .pxToVw(margin-bottom, 8);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff6600;
    }
    > .desc {
      text-align: center;
      .pxToVw(line-height, 25);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .main {
      .pxToVw(margin-left, 1020);
      .info {
        padding: 197 * @unitVw 0;
        .data-box {
          display: flex;
          align-items: center;
          .data-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .pxToVw(margin-right, 57);
            &:nth-child(1) {
              .pxToVw(width, 108);
            }
            &:nth-child(2) {
              .pxToVw(width, 112);
            }
            &:nth-child(3) {
              .pxToVw(width, 95);
            }
            &:last-child {
              margin-right: 0;
            }
            .num-line {
              display: flex;
              align-items: flex-end;
              .num {
                .pxToVw(line-height, 68);
                .pxToVw(font-size, 60);
                font-family: DINCond-Light, DINCond;
                font-weight: 300;
                color: #333333;
              }
              .unit,
              .plus {
                line-height: 1.4;
                .pxToVw(font-size, 20);
                font-family: PingFangSC-Thin, PingFang SC;
                font-weight: 100;
                color: #333333;
              }
              .unit {
                .pxToVw(margin-bottom, 10);
              }
              .plus {
                align-self: center;
              }
            }
            .text-line {
              .pxToVw(line-height, 25);
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
            }
          }
        }
        .desc {
          .pxToVw(width, 580);
          .pxToVw(margin-top, 42);
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #666666;
        }
      }
    }
    .mobile {
      position: absolute;
      .pxToVw(left, 409);
      .pxToVw(bottom, 29);
      .decorate {
        position: absolute;
        &.decorate-1 {
          .pxToVw(left, -89);
          .pxToVw(top, 113);
          .pxToVw(width, 103);
          .pxToVw(height, 99);
        }
        &.decorate-2 {
          .pxToVw(top, 216);
          .pxToVw(right, -65);
          .pxToVw(width, 295);
          .pxToVw(height, 365);
        }
        &.decorate-3 {
          .pxToVw(left, -144);
          .pxToVw(bottom, 127);
          .pxToVw(width, 202);
          .pxToVw(height, 198);
        }
      }
    }
  }
  .swiper-section {
    position: relative;
    .pxToVw(padding-top, 95);
    .pxToVw(padding-bottom, 60);
    .act {
      position: absolute;
      .pxToVw(top, 61);
      left: 50%;
      transform: translateX(-50%);
      .pxToVw(width, 423);
    }
    > h2.title {
      margin: 0;
      .pxToVw(margin-bottom, 9);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff6600;
    }
    > .desc {
      text-align: center;
      .pxToVw(line-height, 25);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    > .swiper {
      position: relative;
      .pxToVw(margin-top, 13);
      padding: 0 300 * @unitVw;
      .act-swiper-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .pxToVw(width, 44);
        .pxToVw(height, 44);
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        &.swiper-button-disabled {
          display: none;
        }
        &.prev {
          .pxToVw(left, 256);
          background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
        }
        &.next {
          .pxToVw(right, 256);
          background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
        }
      }
      .act-swiper-pagination {
        position: absolute;
        z-index: 100;
        .pxToVw(bottom, 9);
        left: 50%;
        transform: translateX(-50%);
        /deep/ .swiper-pagination-bullet {
          .pxToVw(width, 24);
          .pxToVw(height, 6);
          .pxToVw(margin-right, 8);
          .pxToVw(border-radius, 4);
          background-color: #ddd;
          &:last-child {
            margin-right: 0;
          }
          &.swiper-pagination-bullet-active {
            background-color: #ff6600;
          }
        }
      }
      .card-item {
        display: flex;
        align-items: center;
        .info {
          .pxToVw(margin-right, 221);
          .title {
            .pxToVw(margin-bottom, 30);
            line-height: 1.4;
            .pxToVw(font-size, 30);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .data-box {
            display: flex;
            align-items: center;
            .data-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              .pxToVw(margin-right, 60);
              &:last-child {
                margin-right: 0;
              }
              &:nth-child(2) {
                .pxToVw(width, 113);
              }
              .num-line {
                display: flex;
                align-items: flex-end;
                .num {
                  .pxToVw(line-height, 68);
                  .pxToVw(font-size, 60);
                  font-family: DINCond-Light, DINCond;
                  font-weight: 300;
                  color: #333333;
                }
                .text,
                .unit,
                .plus {
                  line-height: 1.4;
                  .pxToVw(font-size, 20);
                  font-family: PingFangSC-Thin, PingFang SC;
                  font-weight: 100;
                  color: #333333;
                }
                .text,
                .unit {
                  .pxToVw(margin-bottom, 10);
                }
                .plus {
                  align-self: center;
                }
              }
              .text-line {
                .pxToVw(line-height, 25);
                .pxToVw(font-size, 18);
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #666666;
              }
            }
          }
          .desc {
            .pxToVw(width, 579);
            .pxToVw(margin-top, 42);
            .pxToVw(line-height, 25);
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #666666;
          }
        }
        .mobile {
          .decorate {
            position: absolute;
            &.decorate-a {
              .pxToVw(left, -66);
              .pxToVw(top, 122);
              .pxToVw(width, 89);
              .pxToVw(height, 90);
            }
            &.decorate-b {
              .pxToVw(right, -59);
              .pxToVw(top, 210);
              .pxToVw(width, 295);
              .pxToVw(height, 365);
            }
            &.decorate-c {
              .pxToVw(left, -143);
              .pxToVw(bottom, 125);
              .pxToVw(width, 202);
            }
            &.decorate-d {
              .pxToVw(left, -66);
              .pxToVw(top, 85);
              .pxToVw(width, 74);
            }
            &.decorate-e {
              .pxToVw(right, -66);
              .pxToVw(top, 212);
              .pxToVw(width, 295);
              .pxToVw(height, 365);
            }
            &.decorate-f {
              .pxToVw(left, -148);
              .pxToVw(bottom, 123);
              .pxToVw(width, 202);
            }
          }
        }
      }
    }
  }
  .marketing-activity {
    background-color: #f8f8f8;
  }
  .live-broadcast {
    background-color: #fff;
    > .swiper {
      .card-item {
        .mobile {
          .pxToVw(margin-left, 110);
          .pxToVw(margin-right, 214);
        }
        .info {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
